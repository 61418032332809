//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '輸入妳要“搜索”內容'
    }
  },
  data() {
    return {}
  },
  methods: {
    handleInput(evt) {
      this.$emit('input', evt)
    },
    search() {
      this.$emit('search')
    }
  }
}
